const API = {
    LOGIN: '/api/auth/login',
    MENU: '/api/user/menus',
    PROFILE: '/api/user/profile',
    SLACKINFO: '/crm/customer/auth/slack',
    REFRESHTOKEN: '/api/user/refresh/token', // 刷新token
    oauthToken: '/oauth/v2/token',
    AUTHWECOMTOKEN: '/api/auth/wecom', // 根据微信授权code获取token
    AUTHLINK: '/api/auth/link',// 授权code
    WARD: '/api/seo/ward', // 新增修改删除
    OPTIONS: '/api/city/options', // 列表
    UPLOAD: '/crm/community/image/upload', // 上传图片
    SECTION: '/api/seo/section', // 区块
    ARTICLE: '/api/seo/article', // 文章
    ROLE: '/api/system/role', // 角色
    USER: '/api/system/user', // 用户管理
    USERGO: '/crm/system/user/status', // 用户管理(Go)
    SYSTEMMENU: '/api/system/menu', // 授权菜单列表
    CUSTOMER: '/api/customer/user', // 注册用户
    AGENT: '/api/customer/agent', // 代理商
    BANNER: '/api/advertising/banner', // banner
    KEYWORD: '/api/advertising/keyword', // 搜索词
    VIDEO: '/api/advertising/video', // 直播视频
    VIDEOOPTIONS: '/api/advertising/video/options', // 视频相关下拉数据
    UPLOADTOKEN: '/api/common/qiniu/upload/token', // 七牛上传视频token
    RESOURCEDEL: '/api/common/qiniu/resource/delete', // 删除七牛视频
    REPORT: '/api/im/inquiry/report', // 母账户送客日报
    INQUIRYLIST: '/api/im/inquiry/list', // 单个母中介送客明细
    ANALYTICSLOGIN: '/api/analytics/login/im', // IM 登录统计
    TRACKER: '/api/analytics/tracker', // 表单埋点
    TRACKERGO: '/crm/analytics/tracker', // 表单埋点(go)
    TRACKEROPTIONS: '/api/analytics/tracker/options', // 埋点类型下拉列表
    QRDOWNLOAD: '/api/analytics/qr/download', // 二维码app下载
    APPLICATION: '/api/system/application', // app版本管理
    EXPORT: '/api/im/inquiry/export', // Im导出
    ADVERTISINGCHAir: '/api/advertising/chair', // 投资讲座
    NEWLYESTATE: '/api/analytics/newly/estate', // 获取房源登录统计数据
    PAGEVIEW: '/api/analytics/agent/page/view', // 中介PV数据统计
    IMREPORT: '/api/analytics/im/inquiry/report', // im送客统计
    SIGNUPREPORT: '/api/analytics/user/signup/report', // 注册用户数据统计
    BILLINGVISUALIZATION: '/api/customer/billing/visualization',  // 对账单数据统计
    CUSTOMERSTATUSDISTRIBUTION: '/api/analytics/customer/status/distribution', // 客户状态状态分布
    TRACKERDISTRIBUTION: '/api/analytics/customer/status/tracker/distribution', // 担当客户状态分布
    TRACKERTRACKINGTRENDING: '/api/analytics/tracker/tracking/trending', // 某个担当对应概览
    TRACKERTRACKINGDISTRIBUTION: '/api/analytics/tracker/tracking/distribution', // 某个担当对应明细
    CUSTOMERDISTRICT: '/api/analytics/customer/status/tracker/district', // 客户地区分布
    CUSTOMERSOURCE: '/api/analytics/customer/status/tracker/source', // 集客渠道分布
    TRACKERSTATISTICS: '/api/analytics/customer/status/tracker/statistics', // 签约率&&过户率
    CUSTOMERAPPEAL: '/api/analytics/customer/status/tracker/appeal', //客户诉求报表
    INQUIRY: '/api/customer/inquiry', // 垂询列表
    INQUIRYGO: '/crm/inquiry/export', // 垂询列表(go导出)
    AUDIT: '/api/estate/audit', // 新房审核列表
    FEEDBACK: '/api/customer/feedback', // Feedback
    BILLING: '/api/customer/billing', // 对账单
    TELCODE: '/api/common/international/telephone/code', // 获取国别码
    INQUIRYOPTIONS: '/api/customer/inquiry/options', // 获取垂询下拉选项
    ENTRANCE: '/api/advertising/entrance', // 首页版块
    NEWS: '/api/medium/article', // 新闻资讯
    NEWSGO: '/crm/medium/article', // 新闻资讯（go)
    TAG: '/crm/medium/tag', // 标签
    CATEGORY: '/crm/medium/category', // 分类
    UPLOADIMAGETOKEN: '/api/common/qiniu/upload/token', // 获取token
    AUDITCOUNT: '/api/estate/audit/count', // 房源待审核数量
    FEEDBACKCOUNT: '/api/customer/feedback/count', // feedback 待审核数量
    DISTRICT: '/api/geography/district', // 区域管理
    TRENDREPORT: '/api/advertising/trend/report', // 报告下载
    FILEUPLOAD: '/api/common/file/upload', // 文件上传
    ESTATEEXPORT: '/api/analytics/newly/estate/export', // 导出房源数
    CUSTOMERINFOR: '/api/customer/information', // 客户基础信息
    CUSTOMERINFORMATION: '/crm/customer/information', // 客户基础信息
    CUSTOMERINFORGO: '/crm/customer/inquiry', // 客户基础信息(go)
    CUSTOMERASSIGN: '/crm/customer/assign', // 领取客户
    CUSTOMERREQUIREMENT: '/crm/customer/requirement', // 客户诉求
    REQUIREMENTANNEX: '/api/customer/requirement/annex', // 附件列表
    SCHEDULE: '/api/customer/schedule', // 创建日程
    STATISTICS: '/crm/auth/user/statistics', // 获取日程数量
    MESSAGELIST: '/crm/message/index', //获取消息通知列表
    WEBHOOKCHANNEL: '/crm/public/webhook/', // webhookk重试渠道
    MESSAGEINF: '/crm/message', //修改消息通知
    MESSAGEINFALL: '/crm/message/all', //全部已读消息通知
    CUSTOMEROPTIONS: '/api/customer/information/options', // 客户信息下拉列表
    CUSTOMEROPTIONSGO: '/crm/community/options', // 客户信息下拉列表(go)
    CUSTOMERTRACKING: '/api/customer/tracking', // 对应记录
    CUSTOMERTRACKINGCRM: '/crm/tracking', // 对应记录
    RELEASECUSTOMER: '/crm/customer/release', // 放回公海
    CUSTOMERTRACKINGGO: '/crm/tracking/export', // 对应记录导出
    TRACKOPTIONS: '/api/customer/tracking/options', // 对应记录下拉选项
    CUSTOMERUNREGISTER: '/api/customer/unregister', // 客户注销审核
    CUSTOMERSTARTUP: '/api/customer/startup', // app启动记录
    COUNTRY: '/api/geography/country', // 获取国家列表
    AREA: '/crm/geography/district/group', // 获取区域分组
    AREALIST: '/crm/geography/district', // 区域列表编辑分组
    GEONAMESDUSTRICT: '/api/geography/geonames/district/sync', // 自动补全区域
    GEONAMESCOUNTRY: '/api/geography/geonames/country', // 获取国家信息
    DISTRICTNAMES: '/api/geography/geonames/district/names', // 获取语言名称
    CURRENCY: '/api/globalization/currency', // 货币管理
    MANSION: '/api/estate/mansion', // 楼盘
    MANSIONMESSAGE: '/crm/estate/building/index', // 楼盘管理
    MANSIONMESSAGEDETAIL: '/crm/estate/building', //楼盘管理详情,编辑保存
    ESTATE: '/api/estate/index', // 房源查询
    ESTATEDETAIL: '/api/estate/show', // 房源详情
    ADVNOTIFICATION: '/api/advertising/notification', // 推送
    NOTIFICATIONOPTIONS: '/api/advertising/notification/options', // 推送下拉选项
    // TOPIC: '/api/seo/topic/title/list', // 标题模板列表
    BLACKLIST: '/api/customer/blacklist',
    GRABBIMG: '/api/customer/inquiry/', // 设置不可抢单
    SEOKEYWORD: '/api/cgs/seo/keyword', // 关键词列表
    SEOCATEGORY: '/api/cgs/seo/category', // seo分类
    CGSARTICLE: '/api/cgs/seo/article', // 专题文章
    TOPICTAG: '/api/cgs/topic/tag', // 专题标题
    TOPICTITLE: '/api/cgs/topic/title', // 专题文章
    DESCRIPTION: '/api/cgs/topic/description', // 介绍文
    TRENDINGAGENT: '/api/analytics/estate/operate/trending/agent', // 中介房源操作趋势数据
    OPERATETRENDING: '/api/analytics/estate/operate/trending', // 房源操作趋势数据
    ESTATESUMMARY : '/api/analytics/estate/summary', // 房源概览数据
    INQUIRYTRENDING: '/api/analytics/inquiry/trending', // 客户趋势
    CLUESUMMARY: '/api/analytics/clue/summary', // 线索概览
    CUSTOMERSUMMARY: '/api/analytics/customer/summary', // 客户概览统计
    AGENTSUMMARY: '/api/analytics/agent/summary', // 代理商概览统计
    DISTRICTDISTRIBUTION: '/api/analytics/estate/district/distribution', // 房源区域分布数据
    TYPEDISTRIBUTION: '/api/analytics/estate/type/distribution', // 房源类型分布数据
    SELLINGTRENDING: '/api/analytics/estate/selling/trending', // 在售房源数
    AGEDISTRIBUTION: '/api/analytics/customer/age/distribution', // 客户年龄分布
    GENDERDISTRIBUTION: '/api/analytics/customer/gender/distribution', // 客户性别分布
    CLUETRENDING: '/api/analytics/clue/trending', // 获取线索趋势数据
    BILLINGTRENDING: '/api/analytics/billing/trending', // 获取付费客户趋势
    CREATETRENDING: '/api/analytics/agent/create/trending', // 代理商新增趋势
    AGENTSTATUS: '/api/analytics/agent/status/distribution', // 获取代理商状态数据
    ALLINQUIRY: '/api/customer/inquiries', // 所有垂询列表
    AGENTRECORDS: '/api/customer/agent/records', // 获取中介登录记录
    SPECIAL: '/crm/special', // 特辑列表
    SPECIALBIND: '/api/estate/feature/bind', // 特辑绑定房源
    ROOM: '/crm/room', // 特辑查询房源
    SPECIALROOM: '/crm/special/room', //特辑绑定房源
    GROUP: '/api/analytics/campaign', // 分组
    FILEUPLOADPRESIGN: '/api/common/file/upload/presign', // 预签名
    // go服务新api
    CHATINQUIRY: '/crm/inquiry/chat', // chat form 垂询
    COMMUNITYAGENT: '/crm/community/agent/search', // webhook列表
    CLEARCACHE: '/crm/system/user/clear/cache', // 清除系统缓存
    WEBHOOKS: '/crm/webhooks', // webhook列表
    WEBHOOKSCONFIG: '/crm/config', // 设置通用接口
    COMMUNITYDISTRICT: '/crm/community/district', // 二级地区
    // 类似LP
    ALBUM: '/crm/album', // lp列表
    REQUIREINPUT: '/crm/customer/request', // 需求录入
    // 地区导出
    DISTRICTEXPORT: '/crm/geography/district/export',
    ACQUISITION: '/crm/customer/source', // 集客渠道
    AUTHOR: '/crm/medium/author', // 客户列表
};
export default API;
